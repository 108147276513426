<template>
  <div class="signup-wrap">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">Sign up</div>
      <SignupFormComponent></SignupFormComponent>
      <div class="has-text-centered terms">By clicking the "Sign up" button, you agree with our
        <a class="has-text-primary" @click="openTermsOfUse">Terms of Use</a>
      </div>
      <social-buttons mode="signup"/>
    </div>
    <div class="has-text-centered to-login">Already have an addy account?
      <span @click="toLoginPage" class="has-text-blue is-clickable">Login</span>
    </div>
  </div>
</template>
<script>
import SignupFormComponent from './SignupForm.vue'
import { mapState } from 'vuex'
import SocialButtons from '@components/button/social-buttons.vue'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  components: {
    SignupFormComponent,
    SocialButtons
  },
  created() {
    this.mixpanel.track('rarv76f7')
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    isAddyPlusSignup() {
      return this.$route.path.startsWith('/addyPlus')
    },
  },
  methods: {
    toLoginPage() {
      if (this.isAddyPlusSignup) this.$router.push('/addyPlus/login')
      else this.$router.push('/login')
    },
    openTermsOfUse() {
      const termsUrl = 'https://addyinvest.com/terms-of-use/'
      if (embeddedInIos()) return window.webkit.messageHandlers.openURL.postMessage(termsUrl)
      window.open(termsUrl)
    },
  }
}
</script>

<style lang="scss" scoped>

.signup-wrap {
  padding: 0 25px 60px;
  > .box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 25px;
    padding-bottom: 34px;
    margin-bottom: 29px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    .page-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      margin-bottom: 15px;
    }
    .terms {
      font-size: 14px;
      color: #939598;
      margin-top: 14px;
      padding: 0 30px;
      line-height: 17px;
      > a {
        font-weight: 600;
      }
    }
  }
  .to-login {
    font-size: 16px;
    line-height: 19px;
    color: #939598;
    padding-bottom: 66px;
    > span {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>
